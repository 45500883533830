@import '/styles/helpers/responsive.scss';
@import '/styles/helpers/mixins.scss';

.mobile_button {
  display: none;
}

@include mobile {
  .mobile_button {
    display: block;
    position: sticky;
    z-index: 2;
    bottom: 0;
    background-color: var(--color-white);
    padding: 20px;

    @include iphone-toolbar;
  }
  
  .container {
    &.with_mobile_button {
      padding-bottom: 0;
    }
  }
}