.layout {
  position: relative;
  z-index: 1;
}

.sticky_cart {
  position: fixed;
  z-index: 6;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  min-width: 385px;
  transform: translateX(100%);
  transition: transform .3s ease-in-out;
  box-shadow: -10px 0px 20px rgba(31, 51, 116, 0.07);

  & > section {
    height: 100%;
  }

  &.opened {
    transform: translateX(0);
  }
}

.cart_backdrop {
  position: fixed;
  z-index: 5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  visibility: hidden;
  transition: visibility .3s ease-in-out, background-color .3s ease-in-out;

  &.visible {
    visibility: visible;
    background-color: rgba(0,0,0, 0.6);
  }
}
