.switcher {
  position: relative;
  border-left: 1px solid var(--color-white);
  padding-left: 40px;
  margin-left: 20px;

  .panel_wrapper {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    padding: 10px 0;
    min-width: 120px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s ease-in-out, visibility .2s ease-in-out, top .2s ease-in-out;

    .panel {
      margin: 10px 0 0;
      background-color: var(--color-white);
      box-shadow: 0px 4px 20px rgba(31, 51, 116, 0.08);
      border-radius: 10px;
      padding: 5px 10px;
    } 
  }

  &:hover {
    .panel_wrapper {
      opacity: 1;
      visibility: visible;
      top: 100%;
    }
  }
}


.flag {
  display: block;
  width: 26px;
  min-width: 26px;
  height: 18px;
  border-radius: 2px;
  overflow: hidden;

  svg {
    height: 100%;
    width: 100%;
    border: 0.5px solid var(--color-grey-200);
    border-radius: 2px;
  }
}

.country {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  color: var(--color-primary);
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
  transition: background-color .3s ease-in-out, opacity .3s;

  &:hover, &.selected {
    background-color: var(--color-secondary-100);
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;

    &:hover {
      background-color: transparent;
    }
  }
}

.current {
  color: var(--color-white);
  cursor: pointer;
}

// MOBILE

.mobile_current {
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: var(--color-white);
  min-height: 45px;
}

.mobile_switcher {
  margin-top: 20px;

  .mobile_country {
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--color-grey-300);
    min-height: 50px;
    padding: 5px 20px 5px 15px;
    margin-bottom: 10px;
    transition: opacity .3s ease-in-out, border-color .3s ease-in-out, background-color .3s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }

    &:disabled {
      opacity: 0.6;
    }

    &.selected {
      border-color: var(--color-secondary);
      background-color: var(--color-secondary-100);

      .circle {
        border-color: var(--color-secondary);

        &::before {
          background-color: var(--color-secondary);
        }
      }
    }
  
    .circle {
      position: relative;
      display: block;
      width: 18px;
      min-width: 18px;
      height: 18px;
      margin-right: 15px;
      border: 1px solid var(--color-grey-300);
      border-radius: 50%;
      transition: border-color .3s ease-in-out;
  
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        min-width: 12px;
        height: 12px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-grey-300);
        transition: background-color .3s ease-in-out;
      }
    }
  }
}