@import '/styles/variables.scss';
@import '/styles/helpers/responsive.scss';
@import '/styles/helpers/mixins.scss';

.cart_wrapper {
  background-color: var(--color-white);
  max-height: 100%;
  overflow-y: auto;

  @include no-scrollbar;

  &.reverse {
    background-color: var(--color-bg);

    .sticky_top, .sticky_bottom {
      background-color: var(--color-bg);
    }
  }

  .total {
    background-color: var(--color-grey-200);
  }
}

.sticky_top, .sticky_bottom {
  position: sticky;
  z-index: 1;
  left: 0;
  width: 100%;
  background-color: var(--color-white);
}

.sticky_top {
  top: 0;
  padding: 30px 40px 20px;
}

.sticky_bottom {
  bottom: 0;
  padding: 20px 40px 40px;
  box-shadow: 0px 0px 0px rgba(31, 51, 116, 0.1);
  transition: box-shadow .3s ease-in-out;

  &.need_scroll {
    box-shadow: -6px 0px 20px rgba(31, 51, 116, 0.1);
  }
}

.total {
  background-color: var(--color-grey-100);
  border-radius: 5px;
}


.counter {
  background-color: var(--color-grey-100);
  border-radius: 5px;
}
