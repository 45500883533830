.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--color-secondary);
  padding: 20px 35px 22px;
  border-radius: 5px;
  color: var(--color-white);
  transition: filter .3s ease-in-out, color .3s ease-in-out, background-color .3s ease-in-out, padding .3s ease-in-out, font-size .3s ease-in-out;
  cursor: pointer;
  font-size: var(--fs-16);
  font-weight: 500;

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    background-color: var(--color-grey-300);
    color: var(--color-grey-500);
    cursor: default;

    &:hover {
      filter: none;
    }
  }

  &.full {
    width: 100%;
  }

  &.small, &.small-bold {
    padding: 10px 15px;
    font-weight: 400;
    font-size: var(--fs-14);
  }

  &.small-bold {
    font-weight: 500;
  }

  &.medium {
    padding: 10px 30px;
    font-weight: 400;
    font-size: var(--fs-14);
  }

  &.inverted-secondary, &.inverted-secondary-transparent {
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover {
      filter: none;
      color: var(--color-secondary-500);
      border-color: var(--color-secondary-500);
    }

    &:disabled {
      border-color: var(--color-grey-500);
      color: var(--color-grey-500);
      cursor: default;
    }
  }

  &.inverted-secondary {
    background-color: var(--color-white);
  }

  &.inverted-secondary-transparent {
    background-color: transparent;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.loader {
  & > div {
    padding: 0 !important;
  }
}