.flex, .inline-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &.d-col {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }

  &.j-center {
    justify-content: center;
  }

  &.j-spb {
    justify-content: space-between;
  }

  &.j-end {
    justify-content: flex-end;
  }

  &.j-stretch {
    justify-content: stretch;
  }

  &.a-center {
    align-items: center;
  }

  &.a-stretch {
    align-items: stretch;
  }

  &.a-start {
    align-items: flex-start;
  }

  &.a-end {
    align-items: flex-end;
  }

  &.d-reverse {
    flex-direction: row-reverse;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  // RESPONSIVE - MOBILE
  @include mobile {
    &.mobile-d-col {
      flex-direction: column;
    }

    &.mobile-d-row {
      flex-direction: row;
    }

    &.mobile-d-row-reverse {
      flex-direction: row-reverse;
    }

    &.mobile-d-col-reverse {
      flex-direction: column-reverse;
    }

    &.mobile-wrap {
      flex-wrap: wrap;
    }

    &.mobile-a-start {
      align-items: flex-start;
    }

    &.mobile-a-end {
      align-items: flex-end;
    }

    &.mobile-a-center {
      align-items: center;
    }

    &.mobile-a-stretch {
      align-items: stretch;
    }

    &.mobile-j-spb {
      justify-content: space-between;
    }

    &.mobile-j-center {
      justify-content: center;
    }

    &.mobile-j-start {
      justify-content: flex-start;
    }

    &.mobile-j-end {
      justify-content: flex-end;
    }
  }

  @include tablet {
    &.tablet-j-spb {
      justify-content: space-between;
    }

    &.tablet-d-col {
      flex-direction: column;
    }

    &.tablet-a-start {
      align-items: flex-start;
    }

    &.tablet-j-start {
      justify-content: flex-start;
    }
  }
}

.inline-flex {
  display: inline-flex;
}

.flex-auto {
  flex: 1 1 auto;
}

@include mobile {
  .mobile-flex-auto {
    flex: 1 1 auto;
  }
}