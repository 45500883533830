@import '/styles/helpers/responsive.scss';

.more_dates {
  display: inline-block;
  background-color: var(--color-secondary-100);
  color: var(--color-secondary);

  &.with_bg {
    padding: 0;
    font-size: var(--fs-14);
    background-color: var(--color-grey-200);
    color: var(--color-grey-800);
  }
}