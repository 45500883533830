.counter {
  padding: 10px 12px;
  color: var(--color-primary);
  
  svg {
    margin-right: 10px;
  }

  span {
    display: inline-block;
    line-height: 1;

    &:first-child {
      font-size: var(--fs-16);
      font-weight: 700;
      padding-right: 3px;
      margin-right: 5px;
      border-right: 1px solid var(--color-primary-200);
    }
    &:nth-child(2) {
      color: var(--color-primary-200);
      font-size: var(--fs-14);
      font-weight: 500;
    }
  }
}