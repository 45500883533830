.max-wrapper { // APP MAX WRAPPER
  width: 100%;
  max-width: 1380px;
  margin: 0 auto;
  padding: 0 60px;

  @include tablet {
    padding: 0 40px;
  }

  @include mobile {
    padding: 0 20px;
  }
}

.c-pointer {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.title-underline,
.title-w-underline {
  position: relative;
  padding-bottom: 8px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 89px;
    height: 1px;
    background-color: var(--color-secondary);
  }
}

.title-w-underline {
  &::after {
    background-color: var(--color-white);
  }
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

@include mobile {
  .mobile-h-100 {
    height: 100%;
  }
}

@include mobile {
  .mobile-w-100 {
    width: 100%;
  }
}

@include tablet {
  .tablet-w-100 {
    width: 100%;
  }
}

.ws-nowrap {
  white-space: nowrap;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.p-relative {
  position: relative;
}

.grey-separator {
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--color-grey-300);
  margin: 20px 0;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
