@import '/styles/helpers/responsive.scss';

.options {
  border-top: 2px dashed var(--color-grey-300);
  padding: 15px 0 0;
  margin-top: 15px;

  @include mobile {
    border-top: none;
    padding: 0;
  }
}

.wrapper {
  @include mobile {
    border-left: 2px solid var(--color-grey-300);
    padding-left: 10px;
  }
}

.mobile_order_1 {
  @include mobile {
    order: 1;
  }
}

.mobile_order_2 {
  @include mobile {
    order: 2;
  }
}

.mobile_order_3 {
  @include mobile {
    order: 3;
  }
}