@import '/styles/helpers/responsive.scss';

.footer {
  position: relative;
  z-index: 1;
  background-color: var(--color-primary-700);
  color: var(--color-white);
  padding: 80px 0 60px;
  overflow: hidden;
  
  @include tablet {
    padding: 50px 0 30px;
  }

  @include mobile {
    background-color: transparent;
    color: var(--color-black);
    padding: 75px 0 0;
    overflow: initial;
  }

  a {
    color: var(--color-white);

    @include mobile {
      color: var(--color-secondary);
      font-weight: 400;
      text-decoration: none;
    }
  }
}

.logo_bg {
  position: absolute;
  bottom: -5px;
  right: -5px;
  max-height: 80%;
  max-width: calc(100% / 3 + 30px);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  opacity: 0.4;

  @include mobile {
    bottom: initial;
    top: 0;
    max-width: 80%;
    width: 80%;
    opacity: 1;
    right: 0;
  }
}

.columns {
  margin: 60px 0 0;

  @include mobile {
    margin: 10px 0 0;
  }
}

.col {
  width: calc(100% / 3);
  padding-right: 30px;

  @include mobile {
    width: 100%;
    padding-right: 0;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.6;

  @include mobile {
    margin-bottom: 15px;
    line-height: 1.4;
  }

  svg, a {
    display: block;
  }

  svg {
    margin-bottom: 2px;
  }
}

.links_title {
  position: relative;
  color: var(--color-grey-100);
  font-size: var(--fs-14);
  margin-bottom: 15px;

  @include mobile {
    color: var(--color-black);
    padding-bottom: 8px;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: var(--fs-16);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 89px;
      height: 1px;
      background-color: var(--color-secondary);
    }
  }
}

.link {
  display: block;
  padding: 7px 0;
  font-weight: 500;

  @include mobile {
    padding: 5px 0;
  }
}

.bottom_links {
  @include mobile {
    background-color: var(--color-grey-200);
    padding: 20px 10px;
  }

  .bottom_link {
    opacity: 0.5;
    border-right: 1px solid var(--color-white);
    padding: 0 20px;
    line-height: 1.2;
    text-align: center;
    transition: all .2s ease-in-out;

    @include mobile {
      opacity: 1;
      padding: 0 10px;
      border-right-color: var(--color-secondary);
    }

    &:hover {
      opacity: 1;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
      padding-right: 0;
    }
  }
}
