@font-face {
  font-family: "Dinot";
  src: url("../public/fonts/Dinot-300.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Dinot";
  src: url("../public/fonts/Dinot-400.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Dinot";
  src: url("../public/fonts/Dinot-500.ttf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Dinot";
  src: url("../public/fonts/Dinot-700.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Dinot";
  src: url("../public/fonts/Dinot-900.ttf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

/* Fonts */
:root {
  --font-primary: "Dinot", sans-serif;
}

$weights: ('300', '400', '500', '700', '900');

@each $weight in $weights {
  .fw-#{$weight} {
    font-weight: #{$weight};
  }
}

@each $weight in $weights {
  @media all and (max-width: 768px) {
    .mobile-fw-#{$weight} {
      font-weight: #{$weight};
    }
  }
}
