.__react_component_tooltip {
  &.tooltip-custom {
    z-index: 2;
    font-size: var(--fs-14);
    line-height: 1.3;
    font-weight: 400;
    border-radius: 5px;
    padding: 10px 12px;
    text-align: left;
    max-width: 250px;
    text-align: center;
    white-space: normal;

    &.place-bottom {
      margin-top: 20px;
    }

    &.strong_index {
      z-index: 6;
    }

    &.show {
      opacity: 1;
    }
  } 
}

.more-dates {
  background-color: var(--color-grey-200);
  color: var(--color-primary);
  padding: 5px 8px;
  border-radius: 2px;
  margin-left: 10px;
  font-size: var(--fs-12);
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  
  &.left {
    margin-left: 0;
    margin-right: 10px;
  }

  &.no-margin {
    margin-left: 0;
    margin-right: 0;
  }

  @include mobile {
    padding: 2px 8px;
  }
}
