.player {
  border-bottom: 1px solid var(--color-grey-300);
  padding: 20px 0;

  &:first-child {
    padding-top: 5px;
  }
}

.details_opener {
  padding: 10px 0;
  
  &::after {
    content: url('data:image/svg+xml; utf8, <svg width="10" height="8" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1.5L6 6.5L1 1.5" stroke="%23314554" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  }

  &:disabled:not(.current) {
    & > * {
      color: var(--color-grey-500);
    }

    &::after {
      opacity: 0.4;
    }
  }
}