:root {
  /* Colors */
  --color-white: #ffffff;
  --color-sirius-black: #000000;
  --color-black: #132029;
  --color-black-600: #4B6679;
  --color-black-700: #314554;

  --color-bg: #F8F9FC;

  --color-primary-100: #C8CEE7;
  --color-primary-200: #A5AED6;
  --color-primary-300: #6875B8;
  --color-primary-400: #4855A3;
  --color-primary-500: #4F5DAC;
  --color-primary-600: #3F4B96;
  --color-primary-650: #38418A;
  --color-primary: #2c2f73;
  --color-primary-700: #191a3c;

  --color-secondary: #F46E54;
  --color-secondary-100: #F9ECEB;
  --color-secondary-300: #F7AA99;
  --color-secondary-500: #d75f47;

  --color-grey-100: #F8F9FC;
  --color-grey-200: #E9ECF5;
  --color-grey-300: #E7ECF1;
  --color-grey-400: #C5D0D8;
  --color-grey-500: #A2B0BC;
  --color-grey-700: #7E91A1;
  --color-grey-800: #657B8C;

  --color-success-100: #ECF9EC;
  --color-success: #36A333;
  --color-success-100: #D4F2D4;

  --color-alert: #fea92b;
  --color-alert-900: #EA8D00;
  --color-alert-100: #FFEFD6;

  --color-error: #C94F4F;
  --color-error-100: #F9EBEB;

  --color-loader-100-0: #F4F9FA00;
  --color-loader-100-20: #F4F9FA33;
  --color-loader-100-50: #F4F9FA80;
}
