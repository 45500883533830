.collapsible {
  &::after {
    content: url('data:image/svg+xml; utf8, <svg width="12" height="8" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1.5L6 6.5L1 1.5" stroke="%23F46E54" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    transform-origin: center;
    height: 10px;
    width: 20px;
    padding-bottom: 4px;
    transform: rotate(0);
    transition: transform .3s ease-in-out;
  }

  &.active {
    &::after {
      transform: rotate(180deg);
    }
  }

  & + * {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
}
