.rc-collapse {
  background-color: transparent;
  border: none;
  border-radius: 0;

  .rc-collapse-item {
    border: none;
    
    .rc-collapse-header {
      padding: 0;
      display: flex;
      align-items: center;
      color: var(--color-black);

      .rc-collapse-header-text {
        order: 1;
        margin-right: auto;
      }

      .rc-collapse-extra {
        order: 2;
        margin: 0;
      }

      .rc-collapse-expand-icon {
        order: 3;
        margin-left: 15px;
        transition: transform .2s ease-in-out;
      }
    }

    .rc-collapse-content {
      padding: 0;
      background-color: transparent;
      color: var(--color-black);

      .rc-collapse-content-box {
        margin: 0;
      }
    }

    &.rc-collapse-item-disabled {
      .rc-collapse-header {
        background-color: transparent;
        cursor: default;
        color: var(--color-black);
        opacity: 0.8;
      }
    }

    &.rc-collapse-item-active {
      .rc-collapse-expand-icon {
        transform: rotate(180deg);
      } 
    }
  }

  .rc-collapse-custom-icon {
    path {
      stroke-width: 1.5px;
    }
  }
}