.body_loading {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(#E9ECF5, 0.95);
  opacity: 1;
  visibility: visible;
  overflow: hidden;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out, height .3s ease-in-out;
}
.none {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.img_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
}

.loader {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
}
