$sizes: (8, 10, 12, 13, 14, 16, 18, 20, 22, 24, 28, 30, 34, 36, 38, 40, 48, 50, 60, 70, 100, 150);

:root {
  @each $size in $sizes {
    $rem: calc($size / 16);
    --fs-#{$size}: #{$rem}rem;
  }
}

@each $size in $sizes {
  .fs-#{$size} {
    font-size: var(--fs-#{$size});
  }
}

@each $size in $sizes {
  @include mobile {
    .mobile-fs-#{$size} {
      font-size: var(--fs-#{$size});
    }
  }
}

@each $size in $sizes {
  @include tablet {
    .tablet-fs-#{$size} {
      font-size: var(--fs-#{$size});
    }
  }
}
