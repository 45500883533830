.tabs.simple-tabs {
  box-shadow: none;
  margin: 0;

  .tabs__bar {
    height: 35px;
    background-color: transparent;
    box-shadow: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 2px;
      background-color: var(--color-grey-100);
      height: 2px;
      width: 100%;
    }
  }

  .tabs__controls {
    position: relative;
    z-index: 1;
    padding: 0 5px;
    margin: 0 20px;
    flex: 0 0 auto;
    color: var(--color-primary-200);
    letter-spacing: 0;
    font-weight: 500;
    transition: color .3s ease-in-out;

    &:hover {
      background-color: transparent;
    }

    &.is-active {
      color: var(--color-primary);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .tabs__line {
    background-color: var(--color-primary-300);
    height: 4px;
  }

  .tabs__section {
    padding: 20px;
    background-color: var(--color-white);
    
    @include mobile {
      padding: 20px 0 10px;
    }

    &.border-radius {
      border-radius: 10px;
    }
  }

  .tabs__content {
    background-color: transparent;
    transition: transform .5s ease-in-out, height .5s ease-in-out !important;
  }
}
