@import '/styles/helpers/responsive.scss';
@import '/styles/helpers/mixins.scss';

.cart_wrapper {
  background-color: var(--color-bg);
  max-height: 100%;
  overflow-y: auto;

  @include no-scrollbar;

  @include laptop-s {
    background-color: var(--color-white);
  }

  .total {
    background-color: var(--color-grey-200);
  }
}

.sticky_top, .sticky_bottom {
  position: sticky;
  z-index: 1;
  left: 0;
  width: 100%;
  background-color: var(--color-bg);

  @include laptop-s {
    background-color: var(--color-white);
  }
}

.sticky_top {
  top: 0;
  padding: 30px 40px 20px;

  @include tablet {
    padding: 20px;
  }
}

.sticky_bottom {
  bottom: 0;
  padding: 20px 30px 30px;
  box-shadow: 0px 0px 0px rgba(31, 51, 116, 0.1);
  transition: box-shadow .3s ease-in-out;

  &.need_scroll {
    box-shadow: -6px 0px 20px rgba(31, 51, 116, 0.1);

    @include tablet {
      box-shadow: none;
    }
  }

  @include tablet {
    padding: 0;
    margin: 0;
    box-shadow: none;
  }
}

.total {
  background-color: var(--color-grey-100);
  border-radius: 5px;
  padding: 13px 20px;
}

.counter {
  background-color: var(--color-grey-200);
  border-radius: 5px;
}

.legals {
  display: block;

  a {
    color: var(--color-secondary);
  }
}

.player_entry {
  border-bottom: 1px solid var(--color-grey-300);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.discount {
  @include tablet {
    background-color: var(--color-grey-100);
    padding: 10px 20px 20px;
    margin-top: 20px;
  }
}
