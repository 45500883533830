.toggle-checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  /* The switch - the box around the slider */
  label {
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    font-size: var(--fs-14);
    font-weight: 500;
    color: var(--color-black);
  }

  /* Hide default HTML checkbox */
  input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
  }

  /* The slider */
  .slider {
    position: relative;
    z-index: 1;
    display: block;
    cursor: pointer;
    width: 30px;
    min-width: 30px;
    height: 20px;
    background-color: var(--color-grey-300);
    border-radius: 30px;
    transition: 0.4s;

    &.double {
      background-color: var(--color-secondary);
    }

    &::before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      top: 50%;
      background-color: var(--color-white);
      border-radius: 30px;
      transition: 0.4s;
      transform: translateY(-50%);
    }

    &.large {
      width: 34px;
      min-width: 34px;
      height: 20px;

      &::before {
        height: 16px;
        width: 16px;
      }
    }
  }

  input:checked + .slider {
    background-color: var(--color-secondary);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-secondary);
  }

  input:checked + .slider::before {
    transform: translateY(-50%) translateX(10px);
  }

  input:checked + .slider.large::before {
    transform: translateY(-50%) translateX(14px);
  }

  div {
    margin-left: 10px;
    transition: color .3s ease-in-out;

    &.no-label {
      margin-left: 0;
    }

    &.with-second-label {
      padding-bottom: 4px;
      color: var(--color-grey-500);

      &.checked {
        color: var(--color-black);
      }
    }
  }

  .second-label {
    margin-right: 10px;
    padding-bottom: 4px;
    transition: color .3s ease-in-out;
    max-width: calc(50% - 40px);

    &.checked {
      color: var(--color-grey-500);
    }
  }
}