.react-calendar {
  background-color: var(--color-grey-100);
  border: none;
  padding: 10px 5px;
  border-radius: 5px;
  min-width: 600px;

  @include tablet {
    min-width: initial;
  }

  .react-calendar__navigation {
    margin-bottom: 0;

    & > button:disabled {
      background-color: transparent;
    }
  
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__prev-button {
      &::after {
        content: url('data:image/svg+xml; utf8, <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 1L1 7L7 13" stroke="currentColor" stroke-width="0.75" stroke-linecap="square" stroke-linejoin="round"/></svg>');
        display: block;
      }
    }

    .react-calendar__navigation__next-button {
      &::after {
        content: url('data:image/svg+xml; utf8, <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L1 13" stroke="currentColor" stroke-width="0.75" stroke-linecap="square" stroke-linejoin="round"/></svg>');
        display: block;
      }
    }

    &__label {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: var(--fs-16);
      font-weight: 500;
      color: var(--color-black-600);
      text-transform: capitalize;

      &__divider {
        display: none;
      }
    }

    button {
      &:hover, &:focus {
        background-color: transparent;
      }
    }
  }

  .react-calendar__viewContainer {
    margin: 0;
    padding: 20px 40px;

    @include tablet {
      padding: 20px 20px 10px;
    }

    .react-calendar__month-view  {
      margin: 0;
      padding-left: 30px;

      &:first-of-type {
        border-right: 1px solid var(--color-grey-300);
        padding-right: 20px;
        padding-left: 0;

        @include tablet {
          border-right: none;
          padding: 0;
        }
      }

      &__weekdays__weekday {
        padding: 0 10px 10px;
      }
    }
  }

  .react-calendar__month-view__weekdays {
    text-transform: lowercase;
    white-space: nowrap;

    abbr {
      text-decoration: none;
      color: var(--color-primary);
    }
  }

  .react-calendar__month-view__days {
    .react-calendar__tile {
      color: var(--color-grey-500);
      margin: 3px 0;
      padding: 12px 12px 20px;
      white-space: nowrap;

      &--now {
        font-weight: 600;
      }
    }
  }
}

button.react-calendar__tile:enabled:hover, button.react-calendar__tile:enabled:focus {
  background-color: transparent;
}

button.react-calendar__tile--now {
  background: transparent;
}

button.react-calendar__tile--active {
  background-color: transparent;
}

button.react-calendar__tile {
  border: 1px solid transparent;
}

button.react-calendar__tile:disabled {
  background-color: transparent;
}

button.react-calendar__tile.hide_tile {
  &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)):not(:nth-child(8)) {
    display: none;
  }
}