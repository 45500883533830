html,
body {
  background-color: var(--color-bg);
  width: 100%;
  font-size: 16px;
  line-height: 1.4;
  font-family: var(--font-primary);
  font-weight: 300;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--color-black);

  // text size adjust
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

* {
  box-sizing: border-box;
  font-family: var(--font-primary);
  padding: 0;
  margin: 0;
  outline: none;
  word-break: break-word;

  &:-webkit-direct-focus {
    outline: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: var(--color-black);
  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(90%);
  }

  &.no-hover {
    &:hover {
      filter: none;
    }
  }
}

button {
  background-color: transparent;
  color: var(--color-black);
  padding: 0;
  border: none;
  font-size: var(--fs-14);
}

strong {
  font-weight: 500;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 100px var(--color-white) inset !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
