.text-c-primary {
  color: var(--color-primary);
}

.text-c-primary-200 {
  color: var(--color-primary-200);
}

.text-c-primary-500 {
  color: var(--color-primary-500);
}

.text-c-secondary {
  color: var(--color-secondary);
}

.text-c-black-700 {
  color: var(--color-black-700);
}

@include mobile {
  .mobile-text-c-black-700 {
    color: var(--color-black-700);
  }
}

.text-c-black-600 {
  color: var(--color-black-600);
}

.text-c-white {
  color: var(--color-white);
}

.text-c-grey-300 {
  color: var(--color-grey-300);
}

.text-c-grey-500 {
  color: var(--color-grey-500);
}

.text-c-grey-700 {
  color: var(--color-grey-700);
}

.lh-1-2 {
  line-height: 1.2;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

@include mobile {
  .mobile-text-center {
    text-align: center;
  }

  .mobile-text-left {
    text-align: left;
  }
}

.line-through {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase;
}