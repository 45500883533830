@import '/styles/helpers/responsive.scss';

.input_number {
  position: relative;

  input {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
  }
}

.update {
  width: 25px;
  min-width: 25px;
  height: 25px;
  padding: 5px;
  background-color: var(--color-secondary);
  border: 1px solid transparent;
  color: var(--color-white);
  border-radius: 40px;
  cursor: pointer;
  transition: background-color .3s ease-in-out, color .3s ease-in-out, border-color .3s ease-in-out;

  &.minus {
    background-color: transparent;
    color: var(--color-secondary);
    border-color: var(--color-secondary);
  }

  &:disabled {
    cursor: default;
    background-color: var(--color-grey-300);
    color: var(--color-grey-500);
    border-color: var(--color-grey-300);
  }

  &.big {
    width: 35px;
    min-width: 35px;
    height: 35px;

    @include mobile {
      width: 28px;
      min-width: 28px;
      height: 28px;
    }
  }
}

.input_value {
  padding: 0 10px;
  font-weight: 500;
  font-size: var(--fs-14);
  min-width: 35px;
  text-align: center;

  &.big {
    font-size: var(--fs-20);
    padding: 0 15px;

    @include mobile {
      font-size: var(--fs-18);
      padding: 0 10px;
    }
  }
}
