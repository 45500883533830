.select-field {
  .select-field {
    &__indicator-separator {
      display: none;
    }
  
    &__control {
      width: 100%;
      height: 48px;
      min-height: 48px;
      padding: 0 18px;
      border: none;
      box-shadow: none;
      background-color: transparent !important;
      color: var(--color-black-700);
      transition: padding-top .3s ease-in-out;
      appearance: none;
      cursor: pointer;
    }
  
    &__value-container {
      padding: 0;
      white-space: nowrap;
      transition: padding-top .3s ease-in-out;
    }

    &__single-value {
      margin: 0;
      padding: 0;
    }
    
    &__input-container {
      position: relative;
      margin: 0;
      padding: 0;
      color: var(--color-black-700);
    }

    &__placeholder {
      opacity: 0;
      margin: 0;
    }

    &__control--is-focused {
      .select-field__value-container {
        padding-top: 15px;
      }
    }

    &__value-container--has-value {
      padding-top: 15px;
    }

    &__indicator {
      padding: 8px 5px;
      color: var(--color-black-700);
      cursor: pointer;
    }
  }

  // choices panel
  &__menu-portal {
    .select-field {
      &__menu {
        border: none;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 4px 20px rgba(31, 51, 116, 0.07);
        margin-top: 2px;
      }

      &__menu-list {
        padding: 5px 0;
        border-radius: 0 0 10px 10px;
        @include no-scrollbar;
      }

      &__option {
        font-size: var(--fs-14);
        font-weight: 400;
        cursor: pointer;
        padding: 8px 10px;
        margin: 5px 10px;
        width: calc(100% - 20px);
        border-radius: 5px;
        transition: background-color .2s ease-in-out, color .2s ease-in-out;

        &--is-focused {
          background-color: var(--color-grey-100) !important;
          color: var(--color-black-600);
        }

        &--is-selected {
          background-color: var(--color-secondary-100) !important;
          color: var(--color-secondary);
        }
      }
    }
  }
}

.select-with-no-label {
  .select-field {
    &__value-container {
      padding-top: 0 !important;
    }
  }
}
