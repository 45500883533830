.modal__overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    align-items: flex-end;
    background: rgba(0,0,0,0.2);
  }
}

.modal__container {
  position: relative;
  background-color: var(--color-white);
  box-shadow: 0px 4px 20px rgba(31, 51, 116, 0.07);
  max-width: calc(100% - 40px);
  min-width: 400px;
  max-height: calc(100vh - 60px);
  padding: 0;
  border-radius: 10px;
  overflow-y: auto;
  border: 5px solid transparent;

  @include mobile {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: calc(100vh - #{$mobile-header-height} - 20px);
    border-radius: 30px 30px 0 0;
    box-shadow: 0px -10px 20px rgba(19, 31, 72, 0.05);
  }

  &.raw_modal {
    border: none;

    .modal__inner-container {
      padding: 0;
    }

    .modal__header {
      @include mobile {
        padding: 0;
      }
    }
  }

  // MOBILE - FULL SCREEN MODAL
  @include mobile {
    &.full_screen {
      height: 100%;
      max-height: 100%;
      min-height: 100%;
      border-radius: 0;
      border: 0;

      .modal__inner-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        height: 100%;
        padding: 0;

        .modal__header {
          position: relative;
          z-index: 1;
          padding: 18px 50px 18px 20px;
          background-color: var(--color-bg);

          .modal__title {
            text-align: left;
            padding: 0;
            font-weight: 500;
            line-height: 1.2;
          }
        }

        .modal__close {
          top: 8px;
          right: 5px;
        }

        .modal__content {
          position: relative;
          z-index: 2;
          padding-top: 10px;
          flex: 1 1 auto;
        }
      }
    }
  }
}

.modal__inner-container {
  padding: 40px;

  @include mobile {
    padding: 40px 20px 20px;

    @include iphone-toolbar;

    &.no_padding {
      padding: 30px 0 0;
    }
  }
}

.modal__content {
  position: relative;
  z-index: 1;
}

.modal__header {
  position: relative;
  z-index: 2;
  padding-right: 30px;

  @include mobile {
    padding: 0 40px 10px;
  }
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: var(--fs-24);
  line-height: 1.25;
  box-sizing: border-box;

  @include mobile {
    text-align: center;
    font-size: var(--fs-20);
    padding-bottom: 0;
  }
}

.modal__close {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  color: var(--color-black-700);
  padding: 10px;
  cursor: pointer;

  @include mobile {
    top: 5px;
    right: 5px;
  }

  &::before {
    content: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 19.5L19.5 4.5" stroke="currentColor"/><path d="M19.5 19.5L4.5 4.5" stroke="currentColor"/></svg>');
    display: block;
    height: 24px;
    width: 24px;
    min-width: 24px;
  }
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(10%); }
}

.micromodal-slide {
  position: relative;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
}

.micromodal-slide.is-open {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}