.tabs.switch-tabs {
  box-shadow: none;
  max-width: 800px;
  margin: 0;

  .tabs__bar {
    justify-content: center;
    height: 50px;

    background-color: transparent;
    box-shadow: none;
    border: none;

    &-wrap {
      &.white_bar {
        background-color: var(--color-white);
      }
    }
  }

  .tabs__controls {
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
    border-color: var(--color-grey-300);
    border-style: solid;
    border-width: 1px 0 1px 0;
    padding: 13px 20px;
    color: var(--color-black);
    font-size: var(--fs-14);
    font-weight: 500;
    letter-spacing: 0;
    min-width: 160px;
    transition: color .3s ease-in-out;

    @include mobile {
      min-width: initial;
      flex: 1 1 auto;
    }

    &:first-of-type {
      border-radius: 60px 0 0 60px;
      border-width: 1px 0 1px 1px;
    }

    &:last-of-type {
      border-radius: 0 60px 60px 0;
      border-width: 1px 1px 1px 0;
    }

    &:hover {
      background-color: transparent;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 5px;
      left: 5px;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      background-color: transparent;
      border-radius: 60px;
      transition: background-color .3s ease-in-out;
    }

    &.is-active {
      color: var(--color-secondary);

      &::before {
        background-color: var(--color-secondary-100);
      }
    }
  }

  .tabs__line {
    display: none;
  }

  .tabs__section {
    padding: 30px 20px 10px;
    
    @include mobile {
      padding: 20px 0 10px;
    }
  }

  .tabs__content {
    transition: transform .5s ease-in-out, height .5s ease-in-out !important;
  }
}
