.flash {
  position: relative;
  padding: 10px 15px 10px 20px;
  border-radius: 5px;
  font-size: var(--fs-12);
  font-weight: 400;

  &::before {
    content: '';
    position: absolute;
    left: 9px;
    top: 7px;
    bottom: 7px;
    height: calc(100% - 14px);
    width: 2px;
    border-radius: 5px 0 0 5px;
  }

  strong {
    font-weight: 600;
  }

  &.primary {
    background-color: var(--color-grey-200);
    color: var(--color-primary-600);
  
    &::before {
      background-color: var(--color-primary-600);
    }
  }
  
  &.error {
    background-color: var(--color-error-100);
    color: var(--color-error);
  
    &::before {
      background-color: var(--color-error);
    }
  }

  &.alert {
    background-color: var(--color-alert-100);
    color: var(--color-alert-900);
  
    &::before {
      background-color: var(--color-alert-900);
    }
  }

  &.success {
    background-color: var(--color-success-100);
    color: var(--color-success);
  
    &::before {
      background-color: var(--color-success);
    }
  }
}

.icon_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 50%;
  color: var(--color-white);

  &.primary {
    background-color: var(--color-primary-600);
  }
  &.error {
    background-color: var(--color-error);;
  }
  &.alert {
    background-color: var(--color-alert-900);
  }
  &.success {
    background-color: var(--color-success);
  }
}
