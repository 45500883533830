.wysiwyg {
  font-weight: 400;

  &.light-weight {
    font-weight: 300;
  }
  
  p {
    margin-bottom: 15px;
    color: var(--color-primary);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.black {
    p {
      color: var(--color-black);
    }
  }

  a {
    font-weight: 500;
    color: var(--color-secondary);
  }

  u {
    text-underline-offset: 3px; 
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 500;
    margin: 0 0 20px;

    li {
      padding: 5px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
