.bullet_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  li {
    position: relative;

    &::before {
      content: url('data:image/svg+xml; utf8, <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="black" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/><path d="M5 8.4L7.08163 10L11 6" stroke="black" stroke-width="0.75"/></svg>');
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      transform: translateY(3px) scale(1.2);
      opacity: 0.7;
    }

    &.excluded {
      &::before {
        color: var(--color-secondary);
        content: url('data:image/svg+xml; utf8, <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z" stroke="%23F46E54" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/><path d="M6 10L9.91837 6" stroke="%23F46E54" stroke-width="1"/><path d="M9.92188 10L6.00351 6" stroke="%23F46E54" stroke-width="1"/></svg>');
        opacity: 1;
      }
    }
  }
}
