.rc-collapse.questions_answers {
  .rc-collapse-item {
    background-color: var(--color-bg);
    margin: 10px 0;
    padding: 20px;
    border-radius: 5px;
    transition: background-color .3s ease-in-out;

    &.rc-collapse-item-active {
      background-color: var(--color-white);
      
      .rc-collapse-header-text {
        text-shadow: 0.4px 0 0 currentColor;
      }
    }

    .rc-collapse-header-text {
      font-size: var(--fs-16);
      font-weight: 500;
      transition: text-shadow .3s ease-in-out;
    }
  }

  &.white {
    .rc-collapse-item {
      background-color: var(--color-white);
    }
  }

  .rc-collapse-content-box {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: var(--fs-16);
    line-height: 1.4;

    &::before {
      content: '';
      border-top: 2px dashed #E7ECF1;
      display: block;
      width: 100%;
      height: 2px;
      margin: 20px 0;
    }
  }
}
