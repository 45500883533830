.flag {
  display: block;
  padding: 5px 7px;
  border-radius: 3px;
  color: var(--color-primary-500);
  font-weight: 500;
  text-transform: lowercase;
  white-space: nowrap;

  &.noLower {
    text-transform: none;
  }

  &.bold {
    font-weight: 700;
  }

  &::first-letter {
    text-transform: uppercase;
  }

  &.primary {
    background-color: var(--color-grey-200);
    color: var(--color-primary-500);
  }

  &.primary-dark {
    background-color: var(--color-grey-200);
    color: var(--color-primary);
  }

  &.primaryLight {
    background-color: var(--color-grey-200);
    color: var(--color-primary-300);
    font-weight: 400;
  }
  
  &.orange {
    background-color: var(--color-alert-100);
    color: var(--color-alert-900);
  }

  &.white-orange {
    background-color: var(--color-white);
    color: var(--color-alert-900);
  }

  &.grey {
    background-color: var(--color-grey-200);
    color: var(--color-grey-500);
  }

  &.greyLight {
    background-color: var(--color-grey-100);
    border: 1px solid var(--color-grey-400);
    border-radius: 5px;
    color: var(--color-grey-700);
  }

  &.green {
    background-color: var(--color-success-100);
    color: var(--color-success);
  }

  &.secondary {
    background-color: var(--color-secondary-100);
    color: var(--color-secondary);
  }

  &.low_opacity {
    opacity: 0.7;
  }

  &.larger {
    padding: 7px 13px;
  }
}
